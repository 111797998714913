import React, { useEffect, useState } from 'react';

import {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
    BookOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Dropdown, Tag } from 'antd';
import { navigate } from 'gatsby';

import { useAuth } from '../hooks';

const { Header, Sider, Content } = Layout;

export const MainLayout = ({ children }: { children: React.ReactElement }) => {
    const [collapsed, setCollapsed] = useState(false);
    const { logout, loggedInUser, isAdmin, localLoginCheck } = useAuth();

    useEffect(() => {
        localLoginCheck();
    }, []);

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                style={{ background: '#1f1e37' }}
                width="264"
                collapsedWidth={64}
            >
                <div
                    style={{
                        // margin: '16px',
                        height: '64px',
                        background: 'rgba(255, 255, 255, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                    }}
                >
                    {collapsed ? 'APA' : 'AutoAPA'}
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    items={[
                        {
                            key: 'papers',
                            icon: <BookOutlined />,
                            label: 'My Papers',
                            onClick: () => navigate('/'),
                        },
                        {
                            key: 'shared-papers',
                            icon: <LinkOutlined />,
                            label: 'Shared Papers',
                            onClick: () => navigate('/?show=shared'),
                        },
                        {
                            key: 'users',
                            icon: <UserOutlined />,
                            label: 'Users',
                            disabled: !isAdmin,
                            onClick: () => navigate('/admin/users'),
                        },
                    ]}
                    style={{ background: '#1f1e37' }}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                        background: 'white',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {collapsed ? (
                        <MenuUnfoldOutlined onClick={() => setCollapsed(collapsed => !collapsed)} />
                    ) : (
                        <MenuFoldOutlined onClick={() => setCollapsed(collapsed => !collapsed)} />
                    )}

                    <Dropdown
                        overlay={
                            <>
                                <Menu theme="dark" style={{ background: '#1f1e37' }}>
                                    <Menu.Item icon={<UserOutlined />}>
                                        <>
                                            {loggedInUser?.firstName || ''} {loggedInUser?.lastName || ''}{' '}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'admin' && <Tag color="red">Admin</Tag>}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'student' && <Tag color="blue">Student</Tag>}
                                        </>
                                    </Menu.Item>
                                    <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                                        Logout
                                    </Menu.Item>
                                </Menu>
                            </>
                        }
                    >
                        <UserOutlined />
                    </Dropdown>
                </Header>
                <Content
                    style={{
                        margin: '1.5rem',
                        minHeight: 280,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center',
                        overflowY: 'scroll',
                    }}
                >
                    <div
                        style={{
                            maxWidth: 1200,
                            width: '100%',
                        }}
                    >
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
